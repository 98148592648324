import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Moderation policy",
  "route": "/moderation-policy"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Smartlike moderation policy`}</h1>
    <p>{`Smartlike plans to temporarily moderate content until a sufficient number of moderators join the platform. The policy is widely conservative with the primary focus on:`}</p>
    <ul>
      <li parentName="ul">{`tagging sensitive content;`}</li>
      <li parentName="ul">{`mitigating vandalism;`}</li>
      <li parentName="ul">{`arbitrating user tags.`}</li>
    </ul>
    <p>{`If you wish to help us with the job, please contact us. We will eventually need moderators in all languages.
Read more: `}<a parentName="p" {...{
        "href": "/docs/decentralized-crowdsourced-moderation"
      }}>{`decentralized crowdsourced moderation`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      